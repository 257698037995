// i18next-extract-mark-ns-start apple-pay-for-wix
//Payment methods
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Partners} from 'components/Partners';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import wix_applepay from 'images/wix_apple-pay.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {List} from 'components/List';
import {IndexImage} from 'components/landings/IndexImage';
import {IndexBackground} from 'components/landings/IndexBackground';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';

const IndexContent = styled.div`
  padding-top: 90px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const SellingPage: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="apple-pay-for-wix"
        title={t('Wix Store - Apple Pay')}
        description={t(
          'Add the Wix store Apple Pay integration to let iOS users pay with one of the most used digital wallets in the world. Start here ››'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <SectionHeader underline tagName="h1">
                <Trans>Get the Wix store Apple Pay integration</Trans>
              </SectionHeader>
              <Trans parent="p">
                Apple Pay is a thriving digital wallet app with over 500 million users worldwide. To
                stay competitive and adapt to consumer behavior, it’s crucial to upgrade your Wix
                store with Apple Pay. But to do this, you’ll need a Wix payment gateway that lets
                you add Apple Pay to your Wix website.{' '}
              </Trans>
              <Trans parent="p">
                That’s where we come in. Our e-commerce payment gateway comes with a Wix payment
                integration that lets you accept and manage all the most popular payment methods in
                a single platform.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage src={wix_applepay} alt="Wix apple pay" title="Wix apple pay" />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Accept Apple Pay and many other Wix payment methods</Trans>
              </SectionHeader>
              <Trans parent="p">
                Adding{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink> to
                your Wix website means you’ll satisfy more customers, but why stop there? Modern
                consumers want many{' '}
                <InternalPageLink slug="payment-methods">online payment methods</InternalPageLink>{' '}
                to choose from, and not preparing for this demand can result in losing shoppers to
                your competition.
              </Trans>
              <Trans parent="p">
                That’s why we aggregate all major and{' '}
                <BlogLink slug="alternative-payment-methods">alternative payment methods</BlogLink>{' '}
                into one platform. This way, on top of Apple Pay, your customers can enjoy paying
                with <BlogLink slug="digital-wallet-ecommerce">digital wallets</BlogLink> like{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/paypal">PayPal</InternalPageLink>, and{' '}
                <InternalPageLink slug="payment-methods/click-to-pay">
                  Click to Pay
                </InternalPageLink>
                ,{' '}
                <InternalPageLink slug="payment-methods/credit-cards">
                  credit and debit cards
                </InternalPageLink>
                , and <BlogLink slug="local-payment-methods">local payment methods</BlogLink>{' '}
                including <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>in
                Spain.{' '}
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Grow your sales revenue by approving more payments</Trans>
              </SectionHeader>
              <Trans parent="p">
                Build a better checkout experience, approve more payments, and sell more thanks to
                our <Link to="/features/payments-orchestration/">payments orchestration</Link>{' '}
                feature. Instead of relying solely on <Link to="/redsys-alternative/">Redsys</Link>,
                you’ll be able to choose a default payment processor — if it’s experiencing
                downtime, we’ll route payments to a different, better-performing provider. You won’t
                have to worry about lost sales due to false{' '}
                <BlogLink slug="online-payment-failure-message">
                  online payment failure messages
                </BlogLink>{' '}
                and your customer will enjoy a{' '}
                <BlogLink slug="frictionless-payments">frictionless payment experience</BlogLink>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Enjoy the lowest Wix website payment gateway fees</Trans>
              </SectionHeader>
              <Trans parent="p">
                Scaling your e-commerce business requires strong profit margins. That’s why we offer
                dynamic payment gateway pricing. As you sell more, your transactions fees will
                decrease in real-time.
              </Trans>
              <Trans parent="p">
                Choose a flexible Wix payment gateway that grows with your business —{' '}
                <Link to="/pricing/">view pricing here</Link>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content fullWidth>
        <Section style={{maxWidth: 900, margin: 'auto'}}>
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>Add Apple Pay to your Wix website now</Trans>
            </SectionHeader>
            <Trans parent="p">
              The process to integrate your Wix website with our payment gateway is quick and easy.
              Follow these steps to start accepting Wix store Apple Pay (and more payment methods)
              today.
            </Trans>
            <List>
              <Trans parent="li">
                <AnchorLink href="https://dashboard.monei.com/?action=signUp">
                  Sign up for MONEI here
                </AnchorLink>
              </Trans>
              <Trans parent="li">
                <AnchorLink href="https://support.monei.com/hc/es-es/articles/360019794278-How-do-I-activate-Apple-Pay-">
                  Activate Apple Pay payments
                </AnchorLink>{' '}
                in your MONEI dashboard
              </Trans>
              <Trans parent="li">
                Follow the{' '}
                <AnchorLink href="https://docs.monei.com/docs/e-commerce/wix/">
                  Wix integration guide
                </AnchorLink>{' '}
                to connect MONEI to your online store
              </Trans>
              <Trans parent="li">
                Review our{' '}
                <AnchorLink href="https://support.monei.com/hc/en-us/articles/4406122571025-How-to-enable-MONEI-s-payment-gateway-in-Wix-">
                  Wix support article here
                </AnchorLink>
              </Trans>
            </List>
            <Partners />
          </div>
        </Section>
      </Content>
    </>
  );
};

export default SellingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "apple-pay-for-wix"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
